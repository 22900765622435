import React, { useState } from 'react';
import useFetch from 'use-http';
import { Swiper, SwiperSlide } from 'swiper/react';
import GoogleReviewStars from '~/components/Storyblok/custom/GoogleReviewStars';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import useWindowSize from '~/hooks/useWindowSize';
import TextCard from '~/components/Storyblok/elements/TextCard';
import RenderRichText from '~/components/Storyblok/RenderRichText';
import Media from '~/components/Storyblok/elements/Media';

// This shoddy bit is to get the buttons to align to the bottom of the tallest slide
const SliderContainer = styled.div`
  max-width: ${({ blok }) => blok?.maxWidth || '768px'};
  margin: 0 auto;

  &.is-centered {
    .swiper-slide-prev,
    .swiper-slide-next {
      .quote-item {
        // opacity: 0.75;
      }
    }
  }

  .swiper-wrapper {
    display: flex;
  }

  .swiper-slide {
    height: auto;
    // width: auto;
    // max-width: 768px;
  }

  // .text-card {
  //   flex: 1 1 auto;
  //   .buttons {
  //     padding-top: 1.5rem;
  //     flex: 1 1 auto;
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: flex-end;
  //   }
  // }
`;

export default function Slider({ blok }) {
  return <DefaultSlider blok={blok} />;
}
function DefaultSlider({ blok }) {
  const googleReviewsFetch = useFetch(`/api/get-google-reviews?limit=${blok.limit}`, {}, []);
  const reviews = googleReviewsFetch?.data;
  let limitReviews = [];

  const { width } = useWindowSize();
  const layout = blok?.layout || 'default';
  const heading = blok?.heading || `Over <span class="highlight">10,000 firms</span> rely on Proof`;
  const [swiper, setSwiper] = useState(null);
  const [expandedSlides, setExpandedSlides] = useState({});

  if (reviews?.length === 0) return null;

  if (blok.limit) {
    limitReviews = reviews?.slice(0, blok.limit);
  } else {
    limitReviews = reviews;
  }

  const toggleExpand = slideId => {
    setExpandedSlides(prev => ({
      ...prev,
      [slideId]: !prev[slideId]
    }));
  };

  return limitReviews?.length > 0 ? (
    <>
      {heading && (
        <div className="pb-8 max-w-2xl mx-auto">
          <TextCard blok={{ heading, textAlignment: 'text-center', headingFontSize: 'u-h2' }} />
        </div>
      )}

      <SliderContainer blok={blok} className={`select-none relative ${layout === 'bordered' ? 'is-centered' : ''}`}>
        <Swiper
          loop={true}
          slidesPerView={'auto'}
          slidesPerGroup={1}
          spaceBetween={layout === 'bordered' ? 30 : 60}
          centeredSlides={layout === 'bordered'}
          className={`overflow-visible`}
          onSwiper={swiper => setSwiper(swiper)}
          onSlideChange={() => setExpandedSlides({})}
          allowTouchMove={false}>
          {limitReviews &&
            limitReviews.length > 0 &&
            limitReviews.map((slide, index) => {
              let snippet = slide?.snippet;
              if (!slide?.snippet) return null;
              if (slide?.rating < 4) return null;
              const isLongReview = snippet.length > 500;
              const slideId = slide._uid || slide.id || index;

              return (
                <SwiperSlide key={slideId} className="max-w-full" style={{ maxWidth: '400px' }}>
                  {({ isActive, isNext, isPrev }) => {
                    // Reset expanded state when slide is not active
                    const currentlyActive = isActive || isNext || isPrev;
                    /*if (!currentlyActive && expandedSlides[slideId]) {
                      setExpandedSlides(prev => ({
                        ...prev,
                        [slideId]: false
                      }));
                    }*/

                    const displaySnippet = isLongReview && !expandedSlides[slideId] ? snippet.slice(0, 500) + '...' : snippet;

                    return (
                      <div
                        className={`quote-item h-full ${!isActive && 'opacity-100'} text-14px transition-opacity duration-150 cursor-pointer flex flex-col ${layout === 'bordered' ? 'p-6 shadow-sm border border-grey rounded-lg' : ''}`}
                        style={{ maxWidth: '400px' }}>
                        <div className="inline-block">
                          <div className={`inline ${layout === 'bordered' ? 'flex-auto' : ''}`} dangerouslySetInnerHTML={{ __html: displaySnippet }} />
                          {currentlyActive && isLongReview && (
                            <button
                              onClick={e => {
                                e.stopPropagation();
                                toggleExpand(slideId);
                              }}
                              className=" ml-2 text-blue-600 hover:text-blue-800 text-sm font-medium underline hover:text-yellow duration-200 transition-colors">
                              {expandedSlides[slideId] ? 'show less' : 'read more'}
                            </button>
                          )}
                        </div>

                        <div className="flex items-center mt-4 md:mt-6">
                          {slide?.user?.thumbnail?.length > 0 ? (
                            <div className="w-8 h-8 rounded-full overflow-hidden bg-black mr-2">
                              <img src={slide.user.thumbnail} width={32} height={32} />
                            </div>
                          ) : null}
                          <div>
                            <div className="font-bold">{slide.user.name}</div>
                            <div className="" style={{ height: '15px' }}>
                              <GoogleReviewStars rating={slide.rating} hideGoogle={true} fontSize={14} />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                </SwiperSlide>
              );
            })}
        </Swiper>
        <div className="container px-0">
          <div className={`flex justify-end space-x-2 mt-5`}>
            <div
              onClick={() => {
                swiper.slidePrev();
              }}
              className="z-10 cursor-pointer inline-block pointer-events-auto hover:opacity-75 hover:scale-105 transform trans">
              <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
                <rect fill="#E5DDD5" height="32" rx="16" width="32" />
                <g stroke="#1a1a1a">
                  <path d="m10.9818 16h11.0182" stroke-linejoin="round" />
                  <path d="m14.2094 11.7719-4.22802 4.2281 4.22802 4.2281" />
                </g>
              </svg>
            </div>
            <div
              onClick={() => {
                swiper.slideNext();
              }}
              className="z-10 cursor-pointer inline-block pointer-events-auto hover:opacity-75 hover:scale-105 transform trans">
              <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
                <rect fill="#E5DDD5" height="32" rx="16" transform="matrix(-1 0 0 -1 64 64)" width="32" x="32" y="32" />
                <g stroke="#1a1a1a">
                  <path d="m21.0182 16h-11.0182" stroke-linejoin="round" />
                  <path d="m17.7906 20.2281 4.228-4.2281-4.228-4.2281" />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </SliderContainer>
    </>
  ) : null;
}
